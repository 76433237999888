import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ComponentTextblock1 from "../../components/component-textblock-1";
//import ComponentTextblock2 from "../../components/component-textblock-2";
import ComponentTextblock5 from "../../components/component-textblock-5";
//import SectionP60 from "../../components/section/section-p60";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import FooterCta from "../../components/footer-cta";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageCompanyAbout = ({ data, location }) => {
  const intl = useIntl();
  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Cutting-Edge Technology: Cybernics is the next big thing `
            : `Spitzentechnologie: Cybernics ist der nächste große Schritt`
        }
        description={
          intl.locale === "en"
            ? `Cybernics is a frontier science centered on cybernetics, mechatronics and informatics and plays a significant roles in solving some of the most demanding social issues we are  facing today.`
            : `Cybernics ist eine umfassende Wissenschaft, die Kybernetik, Mechatronik und Informatik einschließt. Sie hilft heutige gesellschaftliche Probleme zu lösen.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1ZPlNtONGPY2No1bGFLaxG/cd9dc4798ff3ec20f864bea82faef66b/E185_190.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect sect_PageMission_catch sect-bg-bls">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en"
                ? `The coming paradigm shift is supported by the cutting-edge Cybernic technologies, fusing humans, robotics, and information system`
                : `Der kommende Paradigmenwechsel wird durch die moderne Cybernic-Technologie, die Menschen, Roboter und Informationssystem vereint, unterstützt`
            }
            subtitle={intl.locale === "en" ? `Cybernics` : `Cybernics`}
            content=""
          />

          <div className="flex-row">
            <div className="flex-column-6">
              <h2 className="h3 mt-8">
                {intl.locale === "en"
                  ? `What is Cybernics?`
                  : `Was versteht man unter Cybernics?`}
              </h2>
              <p>
                {intl.locale === "en"
                  ? `“Cybernics” (adjective: Cybernic) is a new academic field that
                is centered around cybernetics, mechatronics and informatics
                fused/combined with various other fields including
                brain/neuroscience, robotics, biology, behavioral science,
                psychology, law, ethics, and business administration. Cybernics
                is championed by Dr. Yoshiyuki Sankai, a professor at the
                University of Tsukuba in Japan. Cybernic technology means the
                practical application of Cybernics to technology.`
                  : ` “Cybernics" (Adjektiv: cybernic) ist ein neues akademisches Feld, das sich um Kybernetik, Mechatronik und Informatik dreht und mit verschiedenen anderen Bereichen wie Gehirn-/Neurowissenschaften, Robotik, Biologie, Verhaltenswissenschaften, Psychologie, Recht, Ethik und Betriebswirtschaft verschmolzen bzw. kombiniert wird. Cybernics wird durch Dr. Yoshiyuki Sankai, einem Professor an der Universität von Tsukuba in Japan, vertreten. Cybernic-Technologie bedeutet die praktische Anwendung von Cybernics auf die Technik.`}
              </p>
            </div>
          </div>

          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/company/cybernics/image-cybernics-1@2x.png"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/company/cybernics/image-cybernics-2@2x.png"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/company/cybernics/image-cybernics-3@2x.png"
                className="mb-4"
                alt=""
              />
            </div>
          </div>

          <div className="flex-row justify-content-between">
            <div className="flex-column-6">
              <ComponentTextblock5>
                {intl.locale === "en"
                  ? `Cybernics Technology`
                  : `Die Cybernic Technologie`}
              </ComponentTextblock5>
              <p className="message-sm mb-5">
                {intl.locale === "en"
                  ? `Cybernic technology was developed as human-assistive technology
                and is expected to be used in a wide range of fields covering
                medicine, welfare, disaster rescue and entertainment. Equipped
                with the research and development foundation to develop
                technology applied to humans, Cybernics spans the gap between
                the various academic fields and social regulations, known as
                “the valley of death,” that has prevented the implementation of
                technology into society, and allows for the smooth realization
                of total systems that encompass humans, machines, and
                information systems from the basic research phases to social
                implementation.`
                  : `Die Cybernic-Technologie wurde als Hilfsmittel für den Menschen entwickelt und soll in einer Vielzahl von Bereichen wie Medizin, Wohlfahrt, Katastrophenschutz und Unterhaltung eingesetzt werden. Ausgestattet mit der Forschungs- und Entwicklungsgrundlage zur Entwicklung von Technologien, die auf den Menschen angewendet werden, schließt Cybernics die Kluft zwischen den verschiedenen akademischen Bereichen und den gesellschaftlichen Vorschriften, die als "Tal des Todes" bekannt sind und die die Implementierung von Technologie in die Gesellschaft verhindert hat. Zugleich ermöglicht Cybernics die reibungslose Realisierung von Gesamtsystemen, die Menschen, Maschinen und Informationssysteme umfassen, von den Phasen der Grundlagenforschung bis zur gesellschaftlichen Implementierung.`}
              </p>
              <ComponentTextblock5>
                {intl.locale === "en"
                  ? `Cybernics Research`
                  : `Cybernics in der Forschung`}
              </ComponentTextblock5>
              <p className="message-sm mb-5">
                {intl.locale === "en"
                  ? `Research on Cybernics started in 1987 when Professor Sankai of
                Tsukuba University, Japan started to plan the basic concepts,
                and on 1991 he started to develop the principle behind the iBF
                theory, which was to become the foundation of the technology
                that combines the human body with technology. In 1995,
                construction of actual prototypes to validate this theory began
                and led to the prototype of Robot Suit HAL® Lower Limb Type, and
                in 1999, a stand-alone experimental prototype of HAL was
                developed. With this model, basic testing on healthy subjects
                and a select group of subjects with disabilities were conducted,
                and the development team was able to acquire strong evidence to
                suggest the validity of their theory. The team’s challenge
                toward complete evaluation and verification of the technology’s
                effectiveness and the implementation of its application in
                society has now begun.`
                  : `Die Cybernics-Forschung begann 1987, als Professor Sankai von der Universität Tsukuba, Japan, mit dem Ausarbeiten der grundlegenden Konzepte begann. 1991 begann er mit der Entwicklung des Prinzips der iBF-Theorie, die zur Grundlage der Technologie werden sollte, die den menschlichen Körper mit der Technologie verbindet. 1995 begann der Bau von echten Prototypen zur Validierung dieser Theorie. Dies führte zum Prototyp des Roboteranzugs HAL® Lower Limb Type. 1999 wurde ein eigenständiger experimenteller Prototyp von HAL entwickelt.
                Mit diesem Modell wurden grundlegende Tests an gesunden Probanden und einer ausgewählten Gruppe von Probanden mit Behinderungen durchgeführt. Das Entwicklungsteam war in der Lage, starke Beweise für die Gültigkeit ihrer Theorie zu sammeln. Die Herausforderung des Teams, die Effektivität der Technologie vollständig zu evaluieren und zu verifizieren und ihre Anwendung in der Gesellschaft zu implementieren, hatte nun begonnen.`}
              </p>
              <ComponentTextblock5>
                {intl.locale === "en"
                  ? `Cybernics in medicine`
                  : `Cybernics in der Medizin`}
              </ComponentTextblock5>
              <p className="message-sm mb-5">
                {intl.locale === "en"
                  ? `Medical Cybernic Systems are intended to reconstruct the neural
                connectivity network (including the connectome of the brain) and
                improve/regenerate the patient’s brain-neuro-physical function
                by promoting the improvement/strengthening of synaptic
                connections between the brain, nerves, and muscles. This is
                achieved by adjusting the patient’s neurological information in
                a way that allows the body to appropriately function, activating
                the neural loop, which importantly includes the sensory nerves,
                through synchronization of the sensory feedback signal with the
                neural signal of the motor intent generated by the brain.`
                  : `Medizinische Cybernic-Systeme sollen das neuronale Konnektivitätsnetzwerk (einschließlich des Konnektoms des Gehirns) rekonstruieren und die Gehirn-/ neurophysikalischen Funktion des Patienten regenerieren, indem sie die Stärke der synaptischen Verbindungen zwischen Gehirn, Nerven und Muskeln fördern. Dies wird erreicht, indem die neurologischen Informationen des Patienten in einer Weise angepasst werden, die es dem Körper ermöglicht, angemessen zu funktionieren. Die neurologische Schleife, die vor allem die sensorischen Nerven einschließt, wird durch die Synchronisation des sensorischen Feedbackssignals mit dem neurologischen Signal der motorischen Absicht des Gehirns, aktiviert.`}
              </p>
              <p>
                {intl.locale === "en"
                  ? `More specifically, in order to allow the body to function
                appropriately even if the signal detected at the periphery is
                too faint to elicit actual muscle movement, an MD can intervene
                by tuning the parameters embedded in the Medical Cybernic
                System. This intervention enables the Medical Cybernic System to
                realize the movement intended by the patient in place of the
                patient’s muscles. Even if the muscles barely function, the
                sensory receptors such as those found in muscle spindle fibers
                get activated by the device’s movement, and this sensory
                information is fed back to the brain in real-time. Because this
                flow/loop of information between the brain/CNS and the periphery
                can be established repeatedly without putting much load on the
                muscles, synaptic connections can be improved/strengthened
                through neuronal plasticity, reconstructing the neural
                connectivity network and thereby promoting functional
                improvement / regeneration. In this way, the technological
                characteristics of the Medical Cybernic Systems were designed
                with the intent to promote the reconstruction of the patient’s
                neural connectivity network (including the connectome of the
                brain), and improve/regenerate the patient’s
                brain-neuro-physical functions.`
                  : `Um dem Körper ein angemessenes Funktionieren zu ermöglichen – selbst dann, wenn das an der Peripherie erkannte Signal zu schwach ist, um eine tatsächliche Muskelbewegung auszulösen – kann ein Arzt eingreifen, indem er die in das medizinische Cybernic-System eingebetteten Parameter abstimmt. Dieser Eingriff ermöglicht es dem medizinischen Cybernic-System, die vom Patienten beabsichtigte Bewegung anstelle der Muskeln des Patienten umzusetzen. Selbst wenn die Muskeln kaum funktionieren, werden die sensorischen Rezeptoren, wie sie in den Muskelspindelfasern zu finden sind, durch die Bewegung des Geräts aktiviert, und leiten sensorische Informationen in Echtzeit an das Gehirn zurück. Da dieser Informationsfluss zwischen dem Gehirn/ZNS und der Peripherie wiederholt hergestellt werden kann, ohne die Muskeln stark zu belasten, können synaptische Verbindungen durch neuronale Plastizität gestärkt werden. Das neuronale Konnektivitätsnetzwerk wird so rekonstruiert und dadurch eine funktionelle Verbesserung/Regeneration gefördert. Auf diese Weise wurden die technologischen Eigenschaften der medizinischen Cybernic-Systeme mit der Absicht entwickelt, die Rekonstruktion des neuronalen Konnektivitätsnetzwerks des Patienten (einschließlich des Konnektoms des Gehirns) zu fördern und die Gehirn-/ neurophysikalischen Funktionen des Patienten zu verbessern/regenerieren.`}
              </p>
            </div>
            <div className="flex-column-5">
              <p class="h2 txt txt-rot mb-5">
                {intl.locale === "en"
                  ? `More Information on Cybernics`
                  : `Weitere Informationen zu Cybernics`}
              </p>
              {intl.locale === "en" ? (
                <div>
                  <strong>Book:</strong>
                  <br />
                  Cybernics
                  <br />
                  Fusion of human, machine and information systems
                  <br />
                  <br />
                  <strong>Editors:</strong> <br />
                  Sankai, Yoshiyuki, Suzuki, Kenji, Hasegawa, Yasuhisa
                  <br />
                  <br />
                  <strong>Publication:</strong>
                  <br />
                  Springer Nature, 2014
                  <br />
                  <br />
                  • Recent research achievements in the world’s leading human
                  assistive technologies in biorobotics
                  <br />
                  • Mutual approaches from engineering in medicine, and medicine
                  in engineering <br />
                  • New perspectives and Interdisciplinary approach for quality
                  of life research between engineering, medicine, law and ethics
                  <br />
                  <br />
                  <a
                    href="https://www.springer.com/gp/book/9784431541585"
                    className="link-border link-border-white"
                  >
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </a>
                </div>
              ) : (
                <div>
                  <strong>Buch:</strong>
                  <br />
                  Cybernics
                  <br />
                  Fusion of human, machine and information systems
                  <br />
                  <br />
                  <strong>Autoren:</strong> <br />
                  Sankai, Yoshiyuki, Suzuki, Kenji, Hasegawa, Yasuhisa,
                  <br />
                  <br />
                  <strong>Verlag:</strong>
                  <br />
                  2014 Springer Nature Verlag
                  <br />
                  <br />
                  <strong>Zusammenfassung:</strong>
                  <br />
                  • Aktuelle Studienergebnisse zu den weltwei tführenden
                  unterstützenden Technologien der Biorobotics
                  <br />
                  • Gemeinsame Ansätze von Ingenieurwissenschaften in der
                  Medizin sowie Medizin in den Ingenieurwissenschaften
                  <br />
                  • Neue Perspektiven und interdisziplinäre Ansätze im Bereich
                  der Lebensqualitätforschung zwischen Ingenieurwissenschaften,
                  Medizin, Rechtwissnschaften und Ethik
                  <br />
                  <br />
                  <a
                    href="https://www.springer.com/gp/book/9784431541585"
                    className="link-border link-border-white"
                  >
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="section-bg-cybernics">
        <StaticImage
          src="../../images/treatment-big-teaser2.png"
          className="mb-4"
          alt=""
        />
        <div className="section-bg-cybernics-box bg-rot p-5 pt-10 pb-9 pr-9">
          <h2 className="h3">
            {intl.locale === "en" ? `HAL Treatment` : `HAL-Therapie`}
          </h2>
          <p>
            {intl.locale === "en"
              ? `All of our medical devices are intended to reconstruct the neural connectivity network, including the connectome of the brain, and to improve and regenerate the patient’s brain-neuro-physical function.`
              : `All unsere mediznischen Geräte beabsichtigen das neuronale Konnektivitätsnetzwerk, einschließlich des Konnektoms des Gehirns, zu rekonstruieren, verbessern und die Gehirnneurophysiologischen Funktionen zu regenerieren.`}
          </p>
          <div>
            <Link
              to="/treatment/pnd/"
              className="link-border link-border-white"
            >
              {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
            </Link>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 txt txt-rot mb-5">
            {intl.locale === "en"
              ? `Learn more about us and the Cybernics Technology`
              : `Erfahren Sie mehr über uns und die Cybernics Technologie`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `About us` : `Über uns`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Our revolutionary technology is a game-changer of what we have
  as medical experts and neuro-rehabilitation professionals.`
                    : `Unsere revolutionäre Technologie ist bahnbrechend für medizinische Experten und Fachkräfte in der neurologischen Rehabilitation.`}
                </p>
                <Link to="/company/about/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cyberdyne Inc.</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The significant existence of technology is to support, assist,
  enhance, and regenerate functions of humans.`
                    : `Die entscheidende Aufgabe der existierende Technologie ist die menschliche Funktion zu unterstützen, verbessern und regenerieren.`}
                </p>
                <Link to="/company/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Vision and Mission`
                    : `Vision und Auftrag`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen.`}
                </p>
                <Link to="/company/mission/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Contact/Access` : `Anfahrt`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Cyberdyne Care Robotics GmbH based in Bochum, North
  Rhine-Westphalia. We welcome people from all over the world.`
                    : `Die Cyberdyne Care Robotics GmbH hat ihren Sitz in Bochum, Nordrhein-Westfalen. Wir begrüßen Menschen aus der ganzen Welt.`}
                </p>
                <div>
                  <Link to="/company/access/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <FooterCta />
    </Layout>
  );
};

export default PageCompanyAbout;
